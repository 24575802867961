import React, { Component, Fragment, useState } from 'react'
import gql from 'graphql-tag'
import { GraphQLFormProvider } from 'react-form-helper'
import { renderInput, renderDisplay, Card, BuildLayout, Select, Button, Modal, Grid, Radio, Switch, ActivityIndicator } from '../generic'
import { withRouter } from 'react-router'
import { getAllGroups } from './GroupsList'
import TextField from '@material-ui/core/TextField'
import { useQuery, withApollo, useMutation } from 'react-apollo'

const GET_EMPLOYEES = gql`
	query Employees {
		employees(limit:1000) {
			id
    	first
      last
  	}
  }
`

const CREATE_GROUP_PRODUCT = gql`
  mutation createGroupProduct($input: CreateGroupProductInput!){
    createGroupProduct(input: $input){
      id
    }
  }
`

const fields = {
    name: {
      type: 'name',
      label: 'Group Name',
      validate: (value) => {
        if (!value) {
          return 'Name required'
        }
      }
    },
    postalCode: {
      type: 'text',
      label: 'Postal Code',
      validate: (value) => {
        if (!value) {
          return 'Postal Code required'
        }
      }
    },
    consultantId: {
      type: 'select',
      label: 'Consultant',
      options: [],
    }
  }


let GroupFormLayout = (props) => {
  const { mode, options, cancel, history, formName } = props

  fields['consultantId'].options = options.sort((a, b) => (a.label > b.label) ? 1 : -1)

  return (
    <Card>
      <br />
      <BuildLayout formName={formName} fieldNames={Object.keys(fields)}  mode={mode} fieldConfig={fields} spacing={3} cancel={() => history.push('/groups')} />
    </Card>
  );
}

GroupFormLayout = withRouter(GroupFormLayout)

const NewGroup = (props) => {
  const { id, history, open, setOpen } = props
  const { data, error, loading } = useQuery(GET_EMPLOYEES, { skip: !open })
  const [createNewGroupProduct] = useMutation(CREATE_GROUP_PRODUCT)
  const [showSpinner, setShowSpinner] = useState(false)

  if (loading) {
    return <ActivityIndicator />
  }

  const allEmployees = ((data && data.employees) || []).map(e => ({ label: `${e.first && e.first} ${e.last}`, value: e.id }))

  const createGroupProduct = async(data) => {
    console.log("CreateGroup data", data)
    const { id } = data.data.createGroup
    const groupId = id
    await createNewGroupProduct({
      variables: 
      {
        input: { groupId, productType: "GROUP_FUNERAL_BSSP" } 
      }, 
        refetchQueries: [{ query: getAllGroups }],
    })
    .then(()=>{
      history.push(`/groups/${groupId}`)
    })
  }

  return (
    <div>
      <Modal
        title="Add New Group"
        open={open}
        fullWidth={true}
        maxWidth="md"
        okText="Continue"
        cancelText="Cancel"
      >
        <Grid item xs={4}>
          {showSpinner && <ActivityIndicator center={false} />}
        </Grid>
        <GraphQLFormProvider
          mode={'add'}
          id={id}
          fields={fields}
          name="newGroup"
          addMutation={gql`
            mutation CreateGroup($input: CreateGroupInput!) {
              createGroup(input: $input) {
                id
                name
                postalCode
                consultantId
              }
            }
          `}
          afterSubmit={(data)=>createGroupProduct(data)}
          renderDisplay={renderDisplay}
          renderInput={renderInput}
          InputFormLayout={(props) => <GroupFormLayout {...props} formName={"newGroup"}  options={allEmployees}/>}
          DisplayFormLayout={GroupFormLayout}
        />
      </Modal>
    </div>
  )
}

export default NewGroup