import React, { Component, Fragment } from 'react'
import { withApollo } from 'react-apollo'
import { withRouter } from 'react-router'
import gql from 'graphql-tag'
import moment from 'moment'
import { Card, DatePicker, ActivityIndicator, Selector, Select, Button, Modal, Grid, Switch, Confirm } from '../generic'
import { creditLifeInformation } from './CreditLife'
import BankAccount from '../bankAccount/BankAccount';

const START_POLICY = gql`
	mutation StartPolicy(
		$id: String
    $paymentBankAccountId: String
		$paymentStartDate: Float
		$smoker: Boolean
    $quoteAccepted: Boolean
    $paymentType: PaymentType
	) {
		startCreditLifePolicy(
			id: $id
			smoker: $smoker
			quoteAccepted: $quoteAccepted
      paymentStartDate: $paymentStartDate
      paymentBankAccountId: $paymentBankAccountId
      paymentType: $paymentType
		) {
			id
			client {
				id
				smoker
        acceptedTerms
			}
			quoteAccepted
			quoteAcceptedDate
      paymentStartDate
      paymentType
      productType
      paymentBankAccount {
        id
        bankName
        branchCode
        accountNo
        ownAccount
        accountHolderName
        accountHolderContactNumber
        accountHolderIdNumber
        accountHolderEmail
        idNumberMatch
        nameMatch
        phoneMatch
        accountExists
        verificationInfo {
          accountAcceptsDebits
        }
        avsRawData
      }
			policyStatus
		}
	}
`;

const REJECT_QUOTE = gql`
  mutation RejectQuote($id: String, $reason: String) {
    rejectCreditLifeQuote(id: $id, reason: $reason) {
      id
      policyStatus
      reason
    }
  }
`

const SEND_SIGNATURE_LINK = gql`
	mutation($creditLifeId: String) {
		creditLifeSignatureLink(creditLifeId: $creditLifeId ) {
      id
		}
	}
`;

const PolicyCheckRow = ({ checked, name }) => (
  <Grid container spacing={1}>
    <Grid item style={{ color: checked ? 'green' : 'red' }} xs={12}>
      <i className={`fa fa-${checked ? 'check' : 'times'}-circle`} />
      &nbsp;
			{name}
    </Grid >
  </Grid >
);

class MissingRequirements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      smoker: props.creditlife.client.smoker,
      quoteAccepted: props.creditlife.quoteAccepted,
      acceptedTerms: this.props.creditlife.client.acceptedTerms,
      signatureId: this.props.creditlife.signatureId,
      paymentBankAccountId: this.props.creditlife.paymentBankAccountId,
      paymentStartDate: this.setPaymentDate(
        props.creditlife.paymentStartDate,
        props.creditlife.client.debtCounselling ? props.creditlife.client.debtCounselling.actionDay : 0
      ),
      paymentType: props.creditlife.paymentType || (props.creditlife.productType && props.creditlife.productType === "OPEN_MARKET" ? "DEBI_CHECK" : "HYPHEN"),
      bankAccount: props.creditlife.paymentBankAccount,
      communications: props.creditlife.client.relatedCommunication,
      loading: false,
      visible: false,
      modal1Visible: false,
      modal2Visible: false,
      loan: '',
      onBasicList: false,
      showActivity: false,
      sentSignatureMessage: false
    };
  }

  showModal = () => {
    this.setState({
      visible: true
    });
  };

  handleOk = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 2000);
  };
  handleCancel = () => {
    this.setState({ visible: false });
  };

  setPaymentDate(paymentDateParm, actionDayParm) {
    const applicationDate = moment();
    let paymentStartDate = moment();

    if (actionDayParm != 0) {
      paymentStartDate = moment(paymentDateParm);
      const dcPayStartDate = moment(paymentDateParm);
      if (!dcPayStartDate) {
        const actionDay = parseInt(actionDayParm);

        if (
          actionDay >
          parseInt(
            moment()
              .endOf('month')
              .format('D')
          )
        ) {
          paymentStartDate = moment()
            .endOf('month')
            .startOf('day');
        } else {
          paymentStartDate = moment()
            .date(actionDay)
            .startOf('day');
        }
      } else if (dcPayStartDate > applicationDate.endOf().add(2, 'days')) {
        paymentStartDate = dcPayStartDate;
      }
      while (paymentStartDate < applicationDate.endOf().add(2, 'days')) {
        paymentStartDate = paymentStartDate.add(1, 'month');
      }
    }
    else { paymentStartDate.add(1, 'days') }

    return paymentStartDate;
  }

  sendSignatureLink = (creditLifeId) => {
    this.props.client
      .mutate({
        mutation: SEND_SIGNATURE_LINK,
        variables: {
          creditLifeId,
        }
      }).then(() => {
        this.setState({ sentSignatureMessage: true });
      })
  }

  startPolicy() {
    const variables = {
      id: this.props.creditlife.id,
      quoteAccepted: !!this.state.quoteAccepted,
      modal2Visible: false,
    };

    if (this.state.smoker === true || this.state.smoker === false) {
      variables.smoker = this.state.smoker;
    }

    if (this.state.paymentStartDate) {
      variables.paymentStartDate = this.state.paymentStartDate.valueOf();
    }

    if (this.state.paymentType) {
      variables.paymentType = this.state.paymentType
    }

    if (this.state.paymentBankAccountId) {
      variables.paymentBankAccountId = this.state.paymentBankAccountId
    }
    this.setState({ showActivity: true })

    this.props.client
      .mutate({
        mutation: START_POLICY,
        variables,
        refetchQueries: [{ query: creditLifeInformation, variables: { id: this.props.creditlife.id } }]
      })
      .then(() => {
        this.setState({ showActivity: false })
        this.props.setPolicyStatus("POLICY_ISSUED")
      });
  }

  updateBank = ({ paymentBankAccountId }) => {
    this.setState({ paymentBankAccountId }, () => console.log('payment bank account set to ', paymentBankAccountId))
    console.log('payment back account is now ', paymentBankAccountId)
    this.props.updateProduct({ paymentBankAccountId })
  }


  rejectTheQuote(declinedReason) {

    console.log('declined reason', declinedReason)
    if (declinedReason == null) { declinedReason =  'Client Affordability' } else { declinedReason = '' }

    this.props.client
      .mutate({
        mutation: REJECT_QUOTE,
        variables: {
          id: this.props.creditlife.id,
          reason: declinedReason || 'Client Affordability'
        },
        refetchQueries: [{ query: creditLifeInformation, variables: { id: this.props.creditlife.id } }]
      })
  }

  changeSmoker = v => {
    this.setState({ smoker: v.target.checked });
  };

  changeAcceptQuote = v => {
    this.setState({ quoteAccepted: v.target.checked });
  };

  changeAcceptTerms = v => {
    this.setState({ acceptedTerms: v.target.checked });
  };

  changePaymentStartDate = v => {
    this.setState({ paymentStartDate: v.target.checked });
  };

  changePaymentType = v => {
    this.setState({ paymentType: v })
  }

  changeBankName = v => {
    this.setState({ bankAccount: { ...this.state.bankAccount, branchCode: v.value, bankName: v.label } })
  }

  changeAccountNo = (e) => {
    const v = e.target.value
    this.setState({ bankAccount: { ...this.state.bankAccount, accountNo: v } })
  }

  disabledDate(current) {
    // Can not select days before today + 3 days
    return (
      current &&
      current <
      moment()
        .endOf('day')
    );
  }

  render() {
    const { hasSignedDO, paymentBankAccountId } = this.state;
    return (<>
      {this.props.productType === "OPEN_MARKET" &&
        <Card><BankAccount {...this.props.creditlife} clientId={this.props.creditlife.client.id} productId={this.props.creditlife.id} updateProduct={this.updateBank} /></Card>
      }

      <Card>
        <h2>Required Information</h2>

        <Grid container spacing={1} alignItems='center'>
          <Grid item xs={4}> <Card>
            {/* <h2>Policy Checklist</h2> */}
            <PolicyCheckRow
              checked={
                !(
                  !this.state.paymentStartDate ||
                  this.state.paymentStartDate <= moment().endOf('day') ||
                  !this.state.acceptedTerms ||
                  !this.state.quoteAccepted ||
                  (this.props.productType === "OPEN_MARKET" && (!this.state.paymentBankAccountId || !this.state.signatureId))
                  // || (this.props.productType === "OPEN_MARKET" && !hasSignedDO)
                )
              }
              name="All Information"
            />
            <PolicyCheckRow checked={this.state.acceptedTerms} name="Terms and Conditions Accepted" />
            <PolicyCheckRow checked={this.state.quoteAccepted} name="Quote Accepted" />
            {/* {this.props.productType === "OPEN_MARKET" && <PolicyCheckRow checked={hasSignedDO} name="Signed DO Mandate" />} */}
            {this.props.productType === "OPEN_MARKET" && <PolicyCheckRow checked={paymentBankAccountId} name="Bank defined" />}
            {this.props.productType === "OPEN_MARKET" && <PolicyCheckRow checked={this.state.signatureId} name="Policy Signed by client" />}
          </Card>
          </Grid >
          <Grid item xs={2}>
            <DatePicker fullWidth id="claimdatepicker" label="Payment Start Date" required 
                disabledDate={this.disabledDate}
                value={this.state.paymentStartDate}
              onChange={(v) => this.setState({ paymentStartDate: v })}
              />
          </Grid >
          <Grid item xs={2}>
            <Card>
              <Switch label="Smoker" value={this.state.smoker} onChange={this.changeSmoker} />
            </Card>
          </Grid >
          <Grid item xs={2}>
            <Card>
              <Switch label="Quote Accepted" value={this.state.quoteAccepted} onChange={this.changeAcceptQuote} />
            </Card>
          </Grid >
          <Grid item xs={2}>
            <Card>
              <Switch label="T&C Accepted" value={this.state.acceptedTerms} onChange={this.changeAcceptTerms} />
            </Card>
          </Grid >
          {/* <Grid  item xs={3}>
              {hasSignedDO ? 'Yes' : 'No'}
          </Grid > */}
        </Grid >
      </Card>

      <br />

      {this.props.productType === "OPEN_MARKET" && !this.state.signatureId && !this.state.sentSignatureMessage &&
        <Button color='secondary' onClick={() => this.sendSignatureLink(this.props.creditlife.id)}> Send Signature Link </Button>
      } &nbsp; &nbsp;
      <br />

      <Grid container spacing={1} style={{ flexGrow: 1, justifyContent: 'center' }}>
        <Grid item xs={2}>
          <Button color="secondary" size="small" disabled={this.state.showActivity} onClick={() => this.setState({ modal2Visible: true })}>
            Reject Policy
          </Button>
          <Modal
            title="Do you want to Reject the Quote?"
            fullWidth
            maxWidth='md'
            open={this.state.modal2Visible}
            onOk={() => {
              this.setState({ modal2Visible: false })
              this.rejectTheQuote(this.state.declinedReason)
            }}
            onCancel={() => this.setState({ modal2Visible: false })}
            onClose={() => this.setState({ modal2Visible: false })}
          >
            Why are you declining this?
            <br />
            <Select
              style={{ width: 200 }}
              options={rejectReasons}
              value={this.state.declinedReason}
              onChange={(v) => this.setState({ declinedReason: vtarget.value })}
              label="Reject Reason"
            />
          </Modal>
        </Grid>


        <Grid item xs={2} >
          <Button
            color="primary"
            size="small"  
            onClick={() => this.setState({ open: true })}
          disabled={
            !this.state.paymentStartDate ||
            this.state.paymentStartDate <= moment().endOf('day') ||
            !this.state.quoteAccepted ||
            (this.props.productType === "OPEN_MARKET" && (!this.state.paymentBankAccountId || !this.state.signatureId)) ||
            this.state.showActivity
          }
        >
          Start Policy
          </Button>

          <Confirm open={this.state.open} title='Send opt-in SMS' description='Press OK to send a WhatsApp opt-in SMS to the client' onOk={() => { setShowConfirm(false); optIn() }} onCancel={() => setShowConfirm(false)} />

          <Confirm open={this.state.open} title={'Do you want to start the policy? '} description={'The policy will start after the first payment.'}
            onOk={() => { this.setState({ open: false }); this.startPolicy() }}
            onCancel={() => this.setState({ confirmReject: false })} />
        </Grid>

      </Grid>
      {this.state.showActivity && <ActivityIndicator center={false} />}





    </>
    );
  }
}
const rejectReasons = [{ value: "Client Affordability", label: "Client Affordability" }, { value: "Client terminated in DC", label: "Client terminated in DC" }, { value: "Below minimum premium", label: "Below minimum premium" }, { value: "Client changed their mind", label: "Client changed their mind" }, { value: "Client Age", label: "Client Age" }, { value: "Other", label: "Other" }]

MissingRequirements = withApollo(MissingRequirements);
MissingRequirements = withRouter(MissingRequirements);

export default MissingRequirements
