import React, { useState, useEffect } from 'react'
import gql from 'graphql-tag'
import { graphql, withApollo } from 'react-apollo'
import moment from 'moment'
import { ActivityIndicator, Card, MaterialTable, DisplayItem } from '../generic'
import { ClientInformation,  ClientDocuments } from '../shared/client'
import ProductCommunication from '../communication/ProductCommunication'

import { printCurrency } from '../../lib/helpers'
import BankAccount from '../bankAccount/BankAccount';
import Numeral from 'numeral';
import 'numeral/locales/en-za.js';
Numeral.locale('en-za'); // switch between locales

export const GET_SAVINGS_INFO = gql`
  query GetSavings($id: String!) {
  savings(id:$id ) {
    id
    paymentBankAccount {
      id
      bankName
      accountNo
      ownAccount
      accountHolderName
      accountHolderContactNumber
      accountHolderIdNumber
      accountHolderEmail
      idNumberMatch
      nameMatch
      phoneMatch
      accountExists
      verificationInfo {
        accountAcceptsDebits
      }
      avsRawData
    }
    payments {
      id
      timestamp
      amount
      reversalReason
    }
    bucketInfo {
      id
      amount
      bucketType
      description
      targetAmount
      targetDate
      percentageFull
    }
    clientId 
    totalUnits {
      unitType
      unitPrice
      amount
      value
    }
    unitTransactions {
      id
      date
      amount
      unitType
      numberOfUnits
      unitPrice {
        id
        buyAmount
      }
    }
  }
}`

const UPDATE_BANK = gql` 
  mutation updateSavings( $id: String!, $input: UpdateSavingsInput!) {
      updateSavings(id: $id, input: $input) {
        id
        paymentBankAccount {
          id
          bankName
          accountNo
          ownAccount
          accountHolderName
          accountHolderContactNumber
          accountHolderIdNumber
          accountHolderEmail
          idNumberMatch
          nameMatch
          phoneMatch
          accountExists
          verificationInfo {
            accountAcceptsDebits
          }
        }
      }
    }`

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  // Render a multi-Select box
  return (
    <select
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined)
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}

const paymentCols = [
  { Header: 'Date', id: 'timestamp', width: 100, accessor: (d) => moment(d.timestamp).format('DD-MM-YYYY') },
  { Header: 'Amount', id: 'amount', align: "right", width: 100, accessor: d => d.amount, Cell: ({ row }) => printCurrency(row.values.amount) },
  { Header: 'Reversal Reason', id: 'reversalReason', align: "right", width: 100, accessor: d => d.reversalReason }
]

const unitCols = [
  { Header: 'Date', id: 'date', accessor: (d) => moment(d.date).format('DD-MM-YYYY') },
  { Header: 'Unit Type', id: 'unitType', accessor:'unitType', Filter: SelectColumnFilter, filter:'Select' },
  { Header: 'Amount', id: 'amount', align:"right", accessor: d => d.amount, sortMethod: (a, b) => Number(a)-Number(b), Cell: ({row}) => printCurrency(row.values.amount)  },
  { Header: 'Units', id: 'numberOfUnits', accessor:'numberOfUnits'}
]

let SavingsInfo = (props) => {

  const { history, client, cancel, data } = props
  //const [bucketsData, setBucketsData] = useState(data.savings && data.savings.bucketInfo)
  // const [activeOnly, setActiveOnly] = useState(false)
  const [showBankSelect, setShowBankSelect] = useState(false)

  // useEffect(() => {
  //   if (data && data.savings) {
  //     if (activeOnly) {
  //       const active = savings.bucketInfo.filter(row => row.targetAmount > 0 || row.amount > 0)
  //       setBucketsData(active)
  //     }
  //     else {
  //       setBucketsData()
  //     }
  //   }
  // }, [data]);

  const { loading, error, savings } = data

  if (loading) { return <ActivityIndicator /> }

  if (error) { return `An error has occurred: ${error}` }

  if (!savings) {
    return <Card>Reload ...</Card>;
  }

  const { id, bucketInfo } = savings


  const updateSavings = (input) => {
    setShowBankSelect(false)

    client.mutate({
      mutation: UPDATE_BANK,
      variables: {
        id,
        input
      },
      refetchQueries: [{ query: GET_SAVINGS_INFO, variables: { id } }]
    })
  }

  const savingsBucketTotal = bucketInfo.reduce((theTotal, row) => row.amount + theTotal, 0)

  return (
    <Card>
      <ClientInformation id={savings.clientId} title='Savings Account Holder' clientType='Savings' refetchQuery={GET_SAVINGS_INFO} cancel={cancel} history={history} clientData={savings.client} savings={savings} productId={savings.id} />

      <BankAccount {...savings} paymentDay={25} clientId={savings.clientId} productType="FUNERAL" productId={savings.id} updateProduct={updateSavings} />

      <Card>
        <h2>Saving {`${bucketInfo ? printCurrency(savingsBucketTotal) : 0}`}</h2>

        <MaterialTable
          data={bucketInfo.filter(b => b.type === 'EMERGENCY' || (b.amount > 0 || b.targetAmount > 0))}
          columns={[
            { Header: 'Bucket', id: 'bucketType', accessor: 'bucketType' },
            { Header: 'Value', id: 'amount', align: "right", accessor: d => d.amount && d.amount > 1 ? printCurrency(d.amount) : "-" },
            { Header: 'Target Value', id: 'targetAmount', align: "right", accessor: d => d.targetAmount, Cell: ({ row }) => row.values.targetAmount ? printCurrency(row.values.targetAmount) : "-" },
            { Header: 'Target Date', id: 'targetDate', width: 100, accessor: d => d.targetDate ? moment(d.targetDate).format('DD-MM-YYYY') : "-" },
            { Header: <div style={{ textAlign: "center" }} >Percent Full</div>, id: 'percentageFull', accessor: d => <div style={{ textAlign: "center" }} > {d.percentageFull && d.percentageFull > 1 ? `${Numeral(d.percentageFull).format('###0')}%` : "-"} </div> }
          ]}
        />
      </Card>
   {/*
   <Form layout="inline">
    <Form.Item  label="Show active buckets only">
          <Switch checked={activeOnly} onChange={() => { setActiveOnly(!activeOnly);setNewLoad(true)} } />
      </Form.Item>
    </Form>
   */}

      <Card>
        <h2>Payments</h2>
        {savings && savings.payments && <MaterialTable data={savings && savings.payments} columns={paymentCols} />}
      </Card>

      <Card>
        <h2>Unit Transactions</h2>
        {savings && savings.unitTransactions && <MaterialTable data={savings.unitTransactions} columns={unitCols} />}
      </Card>

      <ProductCommunication clientId={savings.clientId} productId={savings.id} product="SAVINGS"/>

      <ClientDocuments clientId={savings.clientId} />

    </Card>
  )
}

SavingsInfo = withApollo(SavingsInfo)

export default graphql(GET_SAVINGS_INFO, { options: ({ id }) => ({ variables: { id } }) })(SavingsInfo)